export const DEBOUNCE_TIME = 500;

export const FEATURES = {
  CLIENTS: 'clients',
  CLIENTS_V: 'clients(v)',
  USERS: 'users',
  USERS_V: 'users(v)',

  EMISSION_CALCULATOR: 'calculator',

  TE_SUMMARY: 'te_s',
  TE_COMPARATIVE_ACTIVITY: 'te_ca',
  TE_AIR: 'te_a',
  TE_RAIL: 'te_r',
  TE_HOTEL: 'te_h',
  TE_CAR: 'te_c',
  TE_TAXI_RIDESHARE: 'te_tr',
  TE_PERSONAL_VEHICLE: 'te_pv',
  TE_DEFINITIONS: 'te_d',
  TE_WATER_USAGE: 'te_wu',

  TWB_SUMMARY: 'twb_s',
  TWB_FRICTION_POINTS: 'twb_fp',
  TWB_TRAVELER: 'twb_t',
  TWB_DEFINITIONS: 'twb_d',

  TPI_EXECUTIVE_SUMMARY: 'tpi_es',
  TPI_AIR: 'tpi_a',
  TPI_HOTEL: 'tpi_h',
  TPI_CAR: 'tpi_c',
  TPI_RAIL: 'tpi_r',
  TPI_DEFINITIONS: 'tpi_d',

  TTI_SUMMARY: 'tti_s',
  TTI_TRAVEL_COMPLIANCE: 'tti_tc',
  TTI_TRAVEL_SPEND: 'tti_ts',
  TTI_TRAVELER_DETAIL: 'tti_td',
  TTI_MEETINGS_EVENTS: 'tti_me',
  TTI_EXPENSE_ANALYSIS: 'tti_ea',
  TTI_BENCHMARKING: 'tti_b',
  TTI_PREDICTIVE_ANALYSIS: 'tti_pa',
  TTI_SCORECARD: 'tti_sc',
  TTI_DEFINITIONS: 'tti_d',

  PM_360: 'pm_360',
  TC_CALCULATOR: 'tc_calc',
};

export const TRAVEL_EMISSION_FEATURES = [
  FEATURES.TE_SUMMARY,
  FEATURES.TE_COMPARATIVE_ACTIVITY,
  FEATURES.TE_AIR,
  FEATURES.TE_RAIL,
  FEATURES.TE_HOTEL,
  FEATURES.TE_CAR,
  FEATURES.TE_TAXI_RIDESHARE,
  FEATURES.TE_PERSONAL_VEHICLE,
  FEATURES.TE_DEFINITIONS,
  FEATURES.TE_WATER_USAGE,
];

export const TRAVELER_WELL_BEING_FEATURES = [
  FEATURES.TWB_SUMMARY,
  FEATURES.TWB_FRICTION_POINTS,
  FEATURES.TWB_TRAVELER,
  FEATURES.TWB_DEFINITIONS,
];

export const SUSTAINABLE_COLLAB_FEATURES = [
  ...TRAVEL_EMISSION_FEATURES,
  ...TRAVELER_WELL_BEING_FEATURES,
  FEATURES.EMISSION_CALCULATOR,
];

export const TPI_FEATURES = [
  FEATURES.TPI_EXECUTIVE_SUMMARY,
  FEATURES.TPI_AIR,
  FEATURES.TPI_HOTEL,
  FEATURES.TPI_CAR,
  FEATURES.TPI_RAIL,
  FEATURES.TPI_DEFINITIONS,
];

export const TTI_FEATURES = [
  FEATURES.TTI_SUMMARY,
  FEATURES.TTI_TRAVEL_COMPLIANCE,
  FEATURES.TTI_TRAVEL_SPEND,
  FEATURES.TTI_TRAVELER_DETAIL,
  FEATURES.TTI_MEETINGS_EVENTS,
  FEATURES.TTI_EXPENSE_ANALYSIS,
  FEATURES.TTI_BENCHMARKING,
  FEATURES.TTI_PREDICTIVE_ANALYSIS,
  FEATURES.TTI_SCORECARD,
  FEATURES.TTI_DEFINITIONS,
];

export const PROJECT_MANAGEMENT_FEATURES = [
  FEATURES.PM_360,
];

export const FEATURES_360 = [
  ...TPI_FEATURES,
  ...TTI_FEATURES,
  ...SUSTAINABLE_COLLAB_FEATURES,
  ...PROJECT_MANAGEMENT_FEATURES,
  FEATURES.TC_CALCULATOR,
];

export const ADMINISTRATION_FEATURES = [
  FEATURES.CLIENTS,
  FEATURES.CLIENTS_V,
  FEATURES.USERS,
  FEATURES.USERS_V,
];

export const APPLICATIONS = {
  HOTEL: 'hotel',
  AIR: 'Air',
  A360: '360 analytics',
  INGESTION: 'ingestion',
  ADMINISTRATION: 'administration',
};

export const CLIENTS = {
  ADVITO: 'advito',
};

export const APP_TAB_NAMES = {
  A360: 'Advito Analytics',
  DASHBOARD: 'Dashboard', // TODO: ? remove ?
};

export const ADVITO_CLIENT_NAME = 'Advito';

export const USER_TYPE = {
  ADVITO_INTERNAL: 'Advito internal',
  CLIENT_USER: 'Client user',
};

export const BCD_TRAVEL_CLIENT_ID = '4';
