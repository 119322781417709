import { gql } from '@apollo/client';

export const CLIENT_LIST = gql`
  query ClientList($applicationId: Int, $clientId: String) {
    clientList(applicationId: $applicationId, clientId: $clientId) {
      id
      clientName
      logoPath
    }
  }
`;
