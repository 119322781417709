import { gql } from '@apollo/client';

const applicationFeature = 'featureTag';
const loginResult = `
  id
  displayName
  clientId
  clientName
  sessionToken
  roleIds
  accessToken
  refreshToken
  clientName
  firstName
  lastName
  userType
  email
  timeZone
  sessionExpOn
  sessionExpiration
  ChallengeName
  Session
  ChallengeParameters {
    FRIENDLY_DEVICE_NAME
    MFAS_CAN_SETUP
    USER_ID_FOR_SRP
  }
  applicationAccess {
    advitoApplicationRoles {
      id
      applicationName
      roleName
    }
    advitoUserApplicationLink {
      id
      pciAccessFlag
      applicationName
      applicationTag
    }
    client {
      id
      clientName
      logoPath
      clientAdvitoApplications {
        id
        applicationName
      }
    }
    clientApplicationFeatures {
      ${applicationFeature}
    }
    userApplicationFeatures {
      ${applicationFeature}
    }
  }
`;

export const QUERY_GET_USER_INFO = gql`
  {
    getUserInfo {
      ${loginResult}
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout(
    $sessionToken: String
  ) {
    logout(sessionToken: $sessionToken)
  }
`;
