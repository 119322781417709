import { FEATURES } from './application';

export const CATEGORY_EVENTS = {
  CLICK: 'click',
  LOSE_FOCUS: 'lose_focus',
  CHANGE: 'change',
  SELECT: 'select',
};

export const EVENTS = {
  CLICK_CALCULATE: { name: 'click_calculate', label: 'Click calculate button', category: CATEGORY_EVENTS.CLICK },
  CLICK_NEXT: { name: 'click_next', label: 'Click next button', category: CATEGORY_EVENTS.CLICK },
  CLICK_PREVIOUS: { name: 'click_previous', label: 'Click previous button', category: CATEGORY_EVENTS.CLICK },
  CLICK_HOTELS_CATEGORY_LIST: { name: 'click_hotels_category_list', label: 'Click to open hotels category list', category: CATEGORY_EVENTS.CLICK },

  CLICK_TE_SUMMARY_TAB: { name: 'click_te_summary_tab', label: 'Click summary tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TE_COMPARATIVE_ACTIVITY_TAB: { name: 'click_te_comparative_activity_tab', label: 'Click comparative activity tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TE_AIR_TAB: { name: 'click_te_air_tab', label: 'Click air tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TE_RAIL_TAB: { name: 'click_te_rail_tab', label: 'Click rail tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TE_HOTEL_TAB: { name: 'click_te_hotel_tab', label: 'Click hotel tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TE_CAR_TAB: { name: 'click_te_car_tab', label: 'Click car tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TE_TAXI_TAB: { name: 'click_te_taxi_tab', label: 'Click taxi/rideshare tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TE_VEHICLE_TAB: { name: 'click_te_vehicle_tab', label: 'Click vehicle tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TE_DEFINITIONS_TAB: { name: 'click_te_definitions_tab', label: 'Click definitions tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TE_WATER_USAGE_TAB: { name: 'click_te_water_usage_tab', label: 'Click definitions tab on travel emissions dashboard', category: CATEGORY_EVENTS.CLICK },

  CLICK_TWB_SUMMARY_TAB: { name: 'click_twb_summary_tab', label: 'Click summary tab on traveler well-being dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TWB_FRICTION_POINTS_TAB: { name: 'click_twb_friction_points_tab', label: 'Click friction points tab on traveler well-being dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TWB_TRAVELER_TAB: { name: 'click_twb_traveler_tab', label: 'Click traveler tab on traveler well-being dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TWB_DEFINITIONS_TAB: { name: 'click_twb_definitions_tab', label: 'Click definitions tab on traveler well-being dashboard', category: CATEGORY_EVENTS.CLICK },

  CLICK_TPI_EXEC_SUMMARY_TAB: { name: 'click_tpi_exec_summary_tab', label: 'Click executive summary tab on travel program insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TPI_AIR_TAB: { name: 'click_tpi_air_tab', label: 'Click air tab on travel program insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TPI_HOTEL_TAB: { name: 'click_tpi_hotel_tab', label: 'Click hotel tab on travel program insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TPI_CAR_TAB: { name: 'click_tpi_car_tab', label: 'Click car tab on travel program insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TPI_RAIL_TAB: { name: 'click_tpi_rail_tab', label: 'Click rail tab on travel program insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TPI_DEFINITIONS_TAB: { name: 'click_tpi_definitions_tab', label: 'Click definitions tab on travel program insights dashboard', category: CATEGORY_EVENTS.CLICK },

  CLICK_TTI_SUMMARY_TAB: { name: 'click_tti_summary_tab', label: 'Click summary tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TTI_TRAVEL_COMPLIANCE_TAB: { name: 'click_tti_travel_compliance_tab', label: 'Click travel compliance tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TTI_TRAVEL_SPEND_TAB: { name: 'click_tti_travel_spend_tab', label: 'Click travel spend tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TTI_TRAVELER_DETAIL_TAB: { name: 'click_tti_traveler_detail_tab', label: 'Click traveler detail tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TTI_MEETINGS_EVENTS_TAB: { name: 'click_tti_meetings_events_tab', label: 'Click meetings events tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TTI_EXPENSE_ANALYSIS_TAB: { name: 'click_tti_expense_analysis_tab', label: 'Click expense analysis tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TTI_BENCHMARKING_TAB: { name: 'click_tti_benchmarking_tab', label: 'Click benchmarking tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TTI_PREDICTIVE_ANALYSIS_TAB: { name: 'click_tti_predictive_analysis_tab', label: 'Click predictive analysis tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TTI_SCORECARD_TAB: { name: 'click_tti_scorecard_tab', label: 'Click scorecard tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },
  CLICK_TTI_DEFINITIONS_TAB: { name: 'click_tti_definitions_tab', label: 'Click definitions tab on total trip insights dashboard', category: CATEGORY_EVENTS.CLICK },

  BLUR_NAME_OF_EVENT: { name: 'lose_focus_name_of_event', label: 'Lose focus on name of event', category: CATEGORY_EVENTS.LOSE_FOCUS },
  BLUR_NUMBER_OF_PARTICIPANTS: { name: 'lose_focus_number_of_participants', label: 'Lose focus on number of participants', category: CATEGORY_EVENTS.LOSE_FOCUS },
  BLUR_EVENT_DURATION: { name: 'lose_focus_event_duration', label: 'Lose focus on event duration', category: CATEGORY_EVENTS.LOSE_FOCUS },
  BLUR_CITY: { name: 'lose_focus_city', label: 'Lose focus on city', category: CATEGORY_EVENTS.LOSE_FOCUS },
  BLUR_NAME_OF_PROPERTY: { name: 'lose_focus_name_of_property', label: 'Lose focus on name of property', category: CATEGORY_EVENTS.LOSE_FOCUS },
  BLUR_NUMBER_ROOMS: { name: 'lose_focus_number_of_rooms', label: 'Lose focus on number of meeting room size', category: CATEGORY_EVENTS.LOSE_FOCUS },
  BLUR_NUMBER_AVG_ROOM_SIZE: { name: 'lose_focus_avg_room_size', label: 'Lose focus on avg meeting room size', category: CATEGORY_EVENTS.LOSE_FOCUS },
  BLUR_MEETING_DURATION: { name: 'lose_focus_meeting_duration', label: 'Lose focus on meeting duration per day', category: CATEGORY_EVENTS.LOSE_FOCUS },
  BLUR_TOTAL_NUMBER_FLIPCHARTS: { name: 'lose_focus_number_flipcharts', label: 'Lose focus on total number flipcharts', category: CATEGORY_EVENTS.LOSE_FOCUS },

  SELECT_AIRPORT: { name: 'select_airport', label: 'Select closest airport from the event', category: CATEGORY_EVENTS.SELECT },
  SELECT_CATEGORY: { name: 'select_category', label: 'Select category', category: CATEGORY_EVENTS.SELECT },
  SELECT_PERCENT_VEGETARIAN_FOOD: { name: 'select_percent_vegetarian_food', label: 'Select percent vegetarian food', category: CATEGORY_EVENTS.SELECT },
  SELECT_PERCENT_OF_FOOD_WASTED: { name: 'select_percent_of_food_wasted', label: 'Select percent of food expected to be wasted', category: CATEGORY_EVENTS.SELECT },
  SELECT_DISPOSAL: { name: 'select_disposal', label: 'Select food waste disposal', category: CATEGORY_EVENTS.SELECT },
  SELECT_ITEM_MATERIAL: { name: 'select_item_material', label: 'Select single usage item material', category: CATEGORY_EVENTS.SELECT },
  SELECT_TSHIRT: { name: 'select_tshirt', label: 'Select t-shirt', category: CATEGORY_EVENTS.SELECT },
  SELECT_NOTEPAD: { name: 'select_notepad', label: 'Select notepad', category: CATEGORY_EVENTS.SELECT },
  SELECT_PEN: { name: 'select_pen', label: 'Select pen', category: CATEGORY_EVENTS.SELECT },
  SELECT_CAP: { name: 'select_cap', label: 'Select cap', category: CATEGORY_EVENTS.SELECT },
  SELECT_CLIENT: { name: 'select_client', label: 'Select client', category: CATEGORY_EVENTS.SELECT },

  CHANGE_FOOD_PROVIDED: { name: 'change_food_provided', label: 'Change food and beverage provided', category: CATEGORY_EVENTS.CHANGE },
  CHANGE_BOTTLES_PROVIDED: { name: 'change_bottles_provided', label: 'Select plastic bottles provided', category: CATEGORY_EVENTS.CHANGE },
  CHANGE_VEGETARIAN_MEALS_PROVIDED: { name: 'change_vegetarian_meals_provided', label: 'Change food and beverage provided', category: CATEGORY_EVENTS.CHANGE },
  CHANGE_DONT_KNOW_MEETING_ROOMS: { name: 'change_dont_know_meeting_rooms', label: 'Change don\'t know meeting rooms', category: CATEGORY_EVENTS.CHANGE },
  CHANGE_MEETING_ROOMS_USED: { name: 'change_use_meeting_rooms', label: 'Change to use meeting rooms', category: CATEGORY_EVENTS.CHANGE },
};

export const MAP_FEATURE_TAGS_TO_EVENTS = {
  [FEATURES.TE_SUMMARY]: EVENTS.CLICK_TE_SUMMARY_TAB,
  [FEATURES.TE_COMPARATIVE_ACTIVITY]: EVENTS.CLICK_TE_COMPARATIVE_ACTIVITY_TAB,
  [FEATURES.TE_AIR]: EVENTS.CLICK_TE_AIR_TAB,
  [FEATURES.TE_RAIL]: EVENTS.CLICK_TE_RAIL_TAB,
  [FEATURES.TE_HOTEL]: EVENTS.CLICK_TE_HOTEL_TAB,
  [FEATURES.TE_CAR]: EVENTS.CLICK_TE_CAR_TAB,
  [FEATURES.TE_TAXI_RIDESHARE]: EVENTS.CLICK_TE_TAXI_TAB,
  [FEATURES.TE_PERSONAL_VEHICLE]: EVENTS.CLICK_TE_VEHICLE_TAB,
  [FEATURES.TE_DEFINITIONS]: EVENTS.CLICK_TE_DEFINITIONS_TAB,
  [FEATURES.TE_WATER_USAGE]: EVENTS.CLICK_TE_WATER_USAGE_TAB,

  [FEATURES.TWB_SUMMARY]: EVENTS.CLICK_TWB_SUMMARY_TAB,
  [FEATURES.TWB_FRICTION_POINTS]: EVENTS.CLICK_TWB_FRICTION_POINTS_TAB,
  [FEATURES.TWB_TRAVELER]: EVENTS.CLICK_TWB_TRAVELER_TAB,
  [FEATURES.TWB_DEFINITIONS]: EVENTS.CLICK_TWB_DEFINITIONS_TAB,

  [FEATURES.TPI_EXECUTIVE_SUMMARY]: EVENTS.CLICK_TPI_EXEC_SUMMARY_TAB,
  [FEATURES.TPI_AIR]: EVENTS.CLICK_TPI_AIR_TAB,
  [FEATURES.TPI_HOTEL]: EVENTS.CLICK_TPI_HOTEL_TAB,
  [FEATURES.TPI_CAR]: EVENTS.CLICK_TPI_CAR_TAB,
  [FEATURES.TPI_RAIL]: EVENTS.CLICK_TPI_RAIL_TAB,
  [FEATURES.TPI_DEFINITIONS]: EVENTS.CLICK_TPI_DEFINITIONS_TAB,

  [FEATURES.TTI_SUMMARY]: EVENTS.CLICK_TTI_SUMMARY_TAB,
  [FEATURES.TTI_TRAVEL_COMPLIANCE]: EVENTS.CLICK_TTI_TRAVEL_COMPLIANCE_TAB,
  [FEATURES.TTI_TRAVEL_SPEND]: EVENTS.CLICK_TTI_TRAVEL_SPEND_TAB,
  [FEATURES.TTI_TRAVELER_DETAIL]: EVENTS.CLICK_TTI_TRAVELER_DETAIL_TAB,
  [FEATURES.TTI_MEETINGS_EVENTS]: EVENTS.CLICK_TTI_MEETINGS_EVENTS_TAB,
  [FEATURES.TTI_EXPENSE_ANALYSIS]: EVENTS.CLICK_TTI_EXPENSE_ANALYSIS_TAB,
  [FEATURES.TTI_BENCHMARKING]: EVENTS.CLICK_TTI_BENCHMARKING_TAB,
  [FEATURES.TTI_PREDICTIVE_ANALYSIS]: EVENTS.CLICK_TTI_PREDICTIVE_ANALYSIS_TAB,
  [FEATURES.TTI_SCORECARD]: EVENTS.CLICK_TTI_SCORECARD_TAB,
  [FEATURES.TTI_DEFINITIONS]: EVENTS.CLICK_TTI_DEFINITIONS_TAB,
};
