import moment from 'moment';
import { DATE_FORMAT } from '@advito/common';
import MomentUtils from '@date-io/moment';
import { convertToLocalTime } from 'date-fns-timezone';

// inspired by https://github.com/mui/material-ui-pickers/issues/329
/* eslint-disable class-methods-use-this */
export class UTCUtils extends MomentUtils {
  format(date, formatString) {
    if (!date) return new Date().toLocaleString();

    // Get the timezone from browser using native methods
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateTmp = Date.parse(date.toLocaleString());

    const localDate = convertToLocalTime(dateTmp, {
      timeZone: timezone,
    });

    return moment(localDate).format(formatString);
  }
}

export const getFormattedDate = (date) => moment(date).utc().format(DATE_FORMAT.DATE);
