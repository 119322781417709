import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ApolloProvider } from '@apollo/client';
import { Notifications } from '@advito/common';
import { HelmetProvider } from 'react-helmet-async';

import 'loaders.css';
import './index.css';

import { baseClient } from 'utilits/apolloClient';
import { UTCUtils } from 'utilits/date';

import theme from './theme';
import history from './history';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <ApolloProvider client={baseClient}>
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider moment={moment} utils={UTCUtils}>
          <CssBaseline />
          <Router history={history}>
            <Notifications>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </Notifications>
          </Router>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
