import get from 'lodash/get';

export const userHasFeatures = (featureOrFeatures, user) => {
  const checkFeatures = Array.isArray(featureOrFeatures) ? featureOrFeatures : [featureOrFeatures];

  const clientApplicationFeatures = get(user, 'applicationAccess.clientApplicationFeatures');
  const userApplicationFeatures = get(user, 'applicationAccess.userApplicationFeatures');

  if (!clientApplicationFeatures || !userApplicationFeatures) {
    return undefined;
  }

  const clientFeatureTags = clientApplicationFeatures.map(
    (feature) => feature.featureTag.toLowerCase(),
  );
  const userFeatureTags = userApplicationFeatures.map(
    (feature) => feature.featureTag.toLowerCase(),
  );

  const hasInClientFeatures = clientFeatureTags.find(
    (featureTag) => checkFeatures.includes(featureTag),
  );
  const hasInUserFeatures = userFeatureTags.find(
    (featureTag) => checkFeatures.includes(featureTag),
  );

  return !!hasInClientFeatures && !!hasInUserFeatures;
};

export const userHasApplicationRole = (applications) => (user) => {
  const applicationsArr = Array.isArray(applications) ? applications : [applications];

  const userApplicationRoles = user.applicationAccess?.advitoApplicationRoles || [];
  const clientApplicationRoles = user.applicationAccess?.client.clientAdvitoApplications || [];

  const hasUserApplicationRoles = !!userApplicationRoles.find(
    (userApplicationRole) => applicationsArr
      .includes(userApplicationRole.applicationName.toLowerCase()),
  );
  const hasClientApplicationRoles = !!clientApplicationRoles.find(
    (clientApplicationRole) => applicationsArr
      .includes(clientApplicationRole.applicationName.toLowerCase()),
  );

  return hasUserApplicationRoles && hasClientApplicationRoles;
};

export const userHasApplicationAccess = (applicationName) => (user) => {
  const userApplications = user.applicationAccess?.advitoUserApplicationLink || [];

  return !!userApplications.find((application) => application.applicationName === applicationName);
};
