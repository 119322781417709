import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_EMISSIONS_DASHBOARD = gql`
  query GetEmissionsDashboard($clientId: String) {
    getEmissionsDashboard(clientId: $clientId) {
      sheets {
        sheetId
        featureTag
      }
      url
      parameters
    }
  }
`;
