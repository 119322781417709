import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const COUNTRIES_FILTERS = gql`
  query Countries {
    countries {
      id
      countryName
    }
  }
`;
