import RobotoWoff from './fonts/roboto/Roboto.woff';
import RobotoWoff2 from './fonts/roboto/Roboto.woff2';
import RobotoWoffTtf from './fonts/roboto/Roboto.ttf';
import RobotoBoldWoff from './fonts/roboto/Robotobold.woff';
import RobotoBoldWoff2 from './fonts/roboto/Robotobold.woff2';
import RobotoBoldTtf from './fonts/roboto/Robotobold.ttf';
import RobotoMediumWoff from './fonts/roboto/Robotomedium.woff';
import RobotoMediumWoff2 from './fonts/roboto/Robotomedium.woff2';
import RobotoMediumTtf from './fonts/roboto/Robotomedium.ttf';

export const roboto = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Roboto'),
    local('Roboto-Regular'),
    url(${RobotoWoff2}) format('woff2'),
    url(${RobotoWoff}) format('woff'),
    url(${RobotoWoffTtf}) format('truetype')
  `,
};

export const robotoBold = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url(${RobotoBoldWoff2}) format('woff2'),
    url(${RobotoBoldWoff}) format('woff'),
    url(${RobotoBoldTtf}) format('truetype')
  `,
};

export const robotoMedium = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url(${RobotoMediumWoff2}) format('woff2'),
    url(${RobotoMediumWoff}) format('woff'),
    url(${RobotoMediumTtf}) format('truetype')
  `,
};
