import { gql } from '@apollo/client';

export const APPLICATION_ACCESS = gql`
{
  applicationAccess {
    client {
      id
      clientName
      logoPath
      clientAdvitoApplications {
        id
        applicationName
      }
    }
    advitoUserApplicationLink {
      id
      pciAccessFlag
      applicationName
      applicationTag
    }
    advitoApplicationRoles {
      id
      roleName
      applicationName
    }
    clientApplicationFeatures {
      id
      advitoApplicationId
      featureTag
    }
    userApplicationFeatures {
      id
      advitoApplicationId
      featureTag
    }
    email
  }
}
`;

export const APP_PARAMS = gql`
{
  appParams {
    advitoApplications {
      id
      applicationFull
      applicationName
      applicationTag
      description
    }
  }
}
`;
