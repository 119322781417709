import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useLayoutEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField, makeStyles } from '@material-ui/core';
import isObject from 'lodash/isObject';
import { useLazyQuery } from '@apollo/client';

import { CLIENT_LIST } from 'api/queries';
import { EVENTS } from 'constant/ga';
import { APPLICATIONS, BCD_TRAVEL_CLIENT_ID } from 'constant/application';
import { AppParamsContext, AppUserContext } from 'context';
import { setGAEvent } from 'utilits/ga';

import { renderOption } from './utils';
import { CurrentClientContext } from './context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '160px',
    marginRight: theme.spacing(4),
  },
  option: {
    color: theme.appColors.neutral90,
  },
  inputRoot: {
    color: theme.appColors.neutral90,
    height: theme.spacing(4),
  },
}));

const ClientSelector = ({
  setLogoPath,
  isAdvitoUser,
}) => {
  const classes = useStyles();

  const appParams = useContext(AppParamsContext);
  const user = useContext(AppUserContext);
  const { client, setClient } = useContext(CurrentClientContext);

  const [
    queryClientList,
    {
      data: { clientList } = {},
      called: clientsCalled,
      loading: clientsLoading,
    },
  ] = useLazyQuery(CLIENT_LIST);

  const analytics360Id = useMemo(
    () => appParams?.advitoApplications.find(
      (app) => app.applicationName.toLowerCase() === APPLICATIONS.A360,
    )?.id,
    [appParams?.advitoApplications],
  );

  const needFetchClients = isAdvitoUser
    && !clientList
    && !clientsCalled
    && analytics360Id;

  const isBCDClient = String(user.clientId) === BCD_TRAVEL_CLIENT_ID;

  useEffect(() => {
    if (needFetchClients || isBCDClient) {
      queryClientList({
        variables: {
          applicationId: analytics360Id,
          clientId: String(user.clientId),
        },
      });
    } else if (!isAdvitoUser) {
      setClient(user.applicationAccess.client);
    }
  }, [needFetchClients, analytics360Id]);

  const handleClientChange = useCallback((event, newClient) => {
    // TODO: idk what the comment below means
    // we should change client only when select from the list
    if (isObject(newClient)) {
      setClient(newClient);
      setLogoPath(newClient.logoPath);
      setGAEvent(EVENTS.SELECT_CLIENT);
    }
  }, [setClient, setLogoPath]);

  const containerRef = useRef();
  useLayoutEffect(() => {
    containerRef.current = document.getElementById('client-selector');
  }, [clientList, analytics360Id]);

  const hasClientAccess = [isAdvitoUser, isBCDClient].some((item) => item);

  if (!hasClientAccess || !containerRef.current || !clientList?.length) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <Autocomplete
        classes={classes}
        freeSolo
        size="small"
        disableClearable
        value={client}
        options={clientList}
        getOptionLabel={(option) => option.clientName || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Client"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              className: classes.inputRoot,
            }}
          />
        )}
        renderOption={renderOption}
        onChange={handleClientChange}
        loading={clientsLoading}
      />,
      containerRef.current,
    )
  );
};

ClientSelector.propTypes = {
  setLogoPath: PropTypes.func.isRequired,
  isAdvitoUser: PropTypes.bool,
};

ClientSelector.defaultProps = {
  isAdvitoUser: false,
};

export default ClientSelector;
