import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CITIES = gql`
  query Cities ($countryId: Int!) {
    cities (countryId: $countryId) {
      id
      cityName
      stateCode
      geoStateId
    }
  }
`;
