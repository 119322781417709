import React from 'react';
import { findAll } from 'highlight-words-core';

export const renderOption = (option, { inputValue }) => {
  const opt = option.clientName;
  const parts = findAll({
    searchWords: [inputValue],
    textToHighlight: opt,
  });

  return (
    <span>
      {
        parts.map((part, index) => {
          const { end, highlight, start } = part;
          const text = opt.slice(start, end);

          return highlight
            ? (<b key={index}>{text}</b>)
            : text;
        })
      }
    </span>
  );
};
