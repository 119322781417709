import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const REGION_FILTERS = gql`
  query Regions($appId: Int!) {
    regions(appId: $appId) {
      id
      regionName
      regionCode
    }
  }
`;
