import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CABINS_QUERY = gql`
  query Cabins {
    cabins {
      name
    }
  }
`;
