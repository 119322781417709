import { gql } from '@apollo/client';

export const CALCULATIONS_WITH_AIRFARE_AND_HOTEL_STAY_QUERY = gql`
  query CalculationWithAirfareAndHotelStay($airFareInput: AirFareInput!, $hotelStayInput: HotelStayInput!) {
    calculationWithAirfareAndHotelStay(airFareInput: $airFareInput, hotelStayInput: $hotelStayInput) {
      airfare {
        cabin
        fare
      }
      hotelStay {
        tier
        fare
      }
    }
  }
`;

export const CALCULATIONS_WITH_AIRFARE_QUERY = gql`
  query CalculationWithAirfares($airFareInput: AirFareInput!) {
    calculationWithAirfares(airFareInput: $airFareInput) {
      cabin
      fare
    }
  }
`;

export const CALCULATIONS_WITH_HOTEL_STAY_QUERY = gql`
  query CalculationWithHotelStay($hotelStayInput: HotelStayInput!) {
    calculationWithHotelStay(hotelStayInput: $hotelStayInput) {
      tier
      fare
    }
  }
`;

export const SAVE_SEARCH_HISTORY = gql`
  mutation SaveSearchHistory($searchingHistoryInput: SearchingHistoryInput!) {
    saveSearchHistory(searchingHistoryInput: $searchingHistoryInput) {
      id
      userId
      clientId
    }
  }
`;
