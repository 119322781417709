import React from 'react';
import { Dashboard, Speed as SpeedIcon } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';

import { ADMIN_ACCESS_ROUTES, ROUTES } from 'constant/routes';
import {
  FEATURES,
  SUSTAINABLE_COLLAB_FEATURES,
  TRAVEL_EMISSION_FEATURES,
  TRAVELER_WELL_BEING_FEATURES,
  TPI_FEATURES,
  TTI_FEATURES,
} from 'constant/application';
import { userHasFeatures } from 'utilits/user';

import { ReactComponent as ProgramDetailIcon } from 'assets/navigationIcons/programDetail.svg';
import { ReactComponent as ProjectIcon } from 'assets/navigationIcons/project.svg';
import { ReactComponent as EarthIcon } from '../assets/navigationIcons/earth.svg';
import { ReactComponent as EmissionsIcon } from '../assets/navigationIcons/emissions.svg';
import { ReactComponent as CalculateIcon } from '../assets/navigationIcons/calculate.svg';
import { ReactComponent as WellnessIcon } from '../assets/navigationIcons/wellness.svg';
import { ReactComponent as CalculatorIcon } from '../assets/navigationIcons/calculator.svg';

const getSustainableCollaborationList = (user) => [
  {
    icon: <SvgIcon component={SpeedIcon} viewBox="0 0 24 24" />,
    title: 'Total Trip Insights',
    link: ROUTES.TOTAL_TRIP_INSIGHTS,
    hidden: !userHasFeatures(TTI_FEATURES, user),
  },
  {
    icon: <SvgIcon component={ProgramDetailIcon} viewBox="0 0 24 24" />,
    title: 'Travel Program Insights',
    link: ROUTES.TRAVEL_PROGRAM_INSIGHTS,
    hidden: !userHasFeatures(TPI_FEATURES, user),
  },
  {
    icon: <SvgIcon component={EarthIcon} viewBox="0 0 24 24" />,
    title: 'GATE4 Insights',
    link: ROUTES.SUSTAINABLE_COLLABORATION.ROOT,
    hidden: !userHasFeatures(SUSTAINABLE_COLLAB_FEATURES, user),
    children: [
      {
        icon: <SvgIcon component={EmissionsIcon} viewBox="0 0 24 24" />,
        title: 'Travel emissions',
        link: `${ROUTES.SUSTAINABLE_COLLABORATION.ROOT}${ROUTES.SUSTAINABLE_COLLABORATION.TRAVEL_EMISSIONS}`,
        hidden: !userHasFeatures(TRAVEL_EMISSION_FEATURES, user),
      },
      {
        icon: <SvgIcon component={WellnessIcon} viewBox="0 0 24 24" />,
        title: 'Traveler well-being',
        link: `${ROUTES.SUSTAINABLE_COLLABORATION.ROOT}${ROUTES.SUSTAINABLE_COLLABORATION.TRAVELER_WELLBEING}`,
        hidden: !userHasFeatures(TRAVELER_WELL_BEING_FEATURES, user),
      },
      {
        icon: <SvgIcon component={CalculateIcon} viewBox="0 0 24 24" />,
        title: 'MICE CO2 impact',
        link: `${ROUTES.SUSTAINABLE_COLLABORATION.ROOT}${ROUTES.SUSTAINABLE_COLLABORATION.EMISSIONS_CALCULATOR}`,
        hidden: !userHasFeatures(FEATURES.EMISSION_CALCULATOR, user),
      },
    ],
  },
  {
    icon: <SvgIcon component={ProjectIcon} viewBox="0 0 24 24" />,
    title: 'Project management',
    link: ROUTES.PROJECT_MANAGEMENT.ROOT,
    hidden: !userHasFeatures(FEATURES.PM_360, user),
  },
  {
    icon: <SvgIcon component={CalculatorIcon} viewBox="0 0 24 24" />,
    title: 'Trip Cost Calculator',
    link: ROUTES.TRIP_COST_CALCULATOR,
    hidden: !userHasFeatures(FEATURES.TC_CALCULATOR, user),
  },
];

const administrationList = [
  {
    icon: <SvgIcon component={Dashboard} viewBox="0 0 24 24" />,
    title: 'Products',
    link: ROUTES.PRODUCTS,
  },
];

function pathNameBelongsTo(pathName, belongsTo) {
  return belongsTo.find((belongTo) => pathName.includes(belongTo));
}

export const getActiveMenuList = (pathName, user) => {
  const hasAuthorization = user.applicationAccess && Object.keys(user.applicationAccess).length;

  if (hasAuthorization) {
    return pathNameBelongsTo(pathName, ADMIN_ACCESS_ROUTES)
      ? administrationList
      : getSustainableCollaborationList(user);
  }
  return [];
};
