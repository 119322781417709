import { gql } from '@apollo/client';

export const TIERS_QUERY = gql`
  query Tiers {
    tiers {
      adjustedId
      adjustedTier
    }
  }
`;

export const HOTEL_CHAIN_BRANDS_QUERY = gql`
  query HotelChainBrands($adjustedTier: String) {
    hotelChainBrands(adjustedTier: $adjustedTier) {
      hotelMasterChainId
      masterChainName
    }
  }
`;

export const HOTEL_PROPERTIES_QUERY = gql`
  query HotelProperties($tier: String, $chainId: Int, $searchString: String, $destinationCityId: String) {
    hotelProperties(tier: $tier, chainId: $chainId, searchString: $searchString, destinationCityId: $destinationCityId) {
      idHmf
      propertyName
      masterChainName
      hotelMasterChainId
      adjustedTier
      adjustedId
    }
  }
`;
