export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  CLIENTS: '/clients',
  PRODUCTS: '/products',
  PROJECT_MANAGEMENT: {
    ROOT: '/project-management',
    CREATE_PROJECT: '/create-project',
    EDIT_PROJECT: '/edit-project',
    PROFILE: '/profile',
  },
  TRIP_COST_CALCULATOR: '/trip-cost-calculator',
  SUSTAINABLE_COLLABORATION: {
    ROOT: '/sustainable-collaboration',
    TRAVEL_EMISSIONS: '/travel-emissions',
    TRAVELER_WELLBEING: '/traveler-well-being',
    EMISSIONS_CALCULATOR: '/mice-co2-impact',
  },
  TOTAL_TRIP_INSIGHTS: '/total-trip-insights',
  TRAVEL_PROGRAM_INSIGHTS: '/travel-program-insights',
  ERROR_500: '/500',
  ERROR_404: '/404',
  NOT_FOUND: '*',
};

export const ADMIN_ACCESS_ROUTES = [
  ROUTES.PRODUCTS,
];

export const A360_ROUTES = [
  ROUTES.TOTAL_TRIP_INSIGHTS,
  ROUTES.TRAVEL_PROGRAM_INSIGHTS,
  ROUTES.SUSTAINABLE_COLLABORATION.ROOT,
  ROUTES.TRIP_COST_CALCULATOR,
];

export const AIR_ROUTES = {
  PM: {
    ROOT: '/project-management',
    CREATE_PROJECT: '/create-project',
  },
  CONTRACTS: '/contract-management',
  GENERAL_DASHBOARDS: '/general-dashboards',
};

export const ADMIN_ROUTES = {
  CLIENTS: '/clients',
  USERS: '/users',
};
