import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_TTI_DASHBOARD = gql`
  query GetTTIDashboard($clientId: String) {
    getTTIDashboard(clientId: $clientId) {
      sheets {
        sheetId
        featureTag
      }
      url
      parameters
    }
  }
`;
