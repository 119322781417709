import { gql } from '@apollo/client';

const PROJECT = `
  id
  projectName
  displayName
  projectDateStart
  projectDateEnd
  airIncluded
  carIncluded
  expenseIncluded
  hotelIncluded
  mealsIncluded
  personalCarIncluded
  railIncluded
  rideshareTaxiIncluded
  g4lAirIncluded
  projectManager
  leadAnalyst
`;

export const ANALYST_MANAGER_LIST_QUERY = gql`
  query AnalystManagerList {
    analystManagerList {
      id
      nameFirst
      nameLast
    }
  }
`;

export const CREATE_PROJECT_MANAGEMENT = gql`
  mutation CreateProjectManagement($projectManagement: ProjectManagementInput!) {
    createProjectManagement(projectManagement: $projectManagement) {
      id
      projectName
    }
  }
`;

export const UPDATE_PROJECT_MANAGEMENT = gql`
  mutation UpdateProjectManagement($projectManagement: ProjectManagementInput!) {
    updateProjectManagement(projectManagement: $projectManagement)
  }
`;

export const PROJECT_LIST_QUERY = gql`
  query Projects($pagination: PaginationInput, $searchString: String) {
    projects(pagination: $pagination, searchString: $searchString) {
      results {
        id
        projectName
        displayName
        projectDateStart
        projectDateEnd
        airIncluded
        carIncluded
        expenseIncluded
        hotelIncluded
        mealsIncluded
        personalCarIncluded
        railIncluded
        rideshareTaxiIncluded
        g4lAirIncluded
        projectManager
        leadAnalyst
        projectTypeId
      }
      total
    }
  }
`;

export const PROJECT_QUERY = gql`
  query Project($id: ID!) {
    project(id: $id) {
      ${PROJECT}
      description
      clientDateStart
      clientDateEnd
      clientName
      baseDate
      projectTypeId
    }
  }
`;

export const STATISTICS_LIST_QUERY = gql`
query Statistics($id: ID!) {
  statistics(id: $id) {
    isChosen
    dataSourceName
    co2AmountStats
    grossSpendStats
    rowsStats
  }
}
`;

export const PROCESS_TABLE_QUERY = gql`
query ProcessTable($id: ID!) {
  processTable(id: $id) {
      rowNum
      month
      status
      processingDate
      isProcessedAir
      isProcessedHotel
      isProcessedRail
      isProcessedCar
      isProcessedExpenses
      isProcessedMeals
      isProcessedPercar
      isProcessedTaxi
      isProcessedG4l
  }
}
`;

export const PROCESS_DATA_FUNCTION_QUERY = gql`
query ProcessData($id: ID!, $startDate: Date!, $endDate: Date!) {
  processData(id: $id, startDate: $startDate, endDate: $endDate)
}
`;

export const PUBLISH_DATA_FUNCTION_QUERY = gql`
query publishData(
  $action: String!, 
  $projectId: String!, 
  $dataSourceTags: [String]!, 
  $periodStart: [String]!, 
  $startDate: Date!, 
  $endDate: Date!
) {
  publishData(
    action: $action, 
    projectId: $projectId, 
    dataSourceTags: $dataSourceTags, 
    periodStart: $periodStart, 
    startDate: $startDate, 
    endDate: $endDate
  )
}
`;
