import React, {
  useEffect,
  useMemo,
  useState,

  Suspense,
} from 'react';
import ReactGA from 'react-ga4';
import { useLazyQuery } from '@apollo/client';
import {
  Redirect,
  Route,
  Switch,

  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, makeStyles } from '@material-ui/core';
import {
  Header,
  Loader,
  BreadcrumbProvider,
  useWebSocket,
} from '@advito/common';

import {
  APP_PARAMS,
  LOGOUT,
  QUERY_GET_USER_INFO,
} from 'api/queries';
import { getActiveMenuList } from 'utilits/menus';
import {
  ADVITO_CLIENT_NAME,
  APP_TAB_NAMES,
  CLIENTS,
  USER_TYPE,
} from 'constant/application';
import { A360_ROUTES, ROUTES } from 'constant/routes';
import { authClient } from 'utilits/apolloClient';
import { findFirstAvailableRoute } from 'utilits/findFirstAvailableRoute';
import { CurrentClientContext } from 'components/ClientSelector';

import BasicLayout from './components/common/BasicLayout';
import history from './history';
import { AppUserContext, AppParamsContext } from './context';

const BannerInfoDeploy = React.lazy(() => import('@advito/common').then((module) => ({ default: module.BannerInfoDeploy })));
const Login = React.lazy(() => import('@advito/common').then((module) => ({ default: module.Login })));
const ErrorPage = React.lazy(() => import('@advito/common').then((module) => ({ default: module.ErrorPage })));
const Navigation = React.lazy(() => import('@advito/common').then((module) => ({ default: module.Navigation })));

const MainView = React.lazy(() => import('components/Views/Main_View'));
const SustainableCollaborationView = React.lazy(() => import('components/Views/SustainableCollaborationView'));
const TravelProgramInsightsView = React.lazy(() => import('components/Views/TravelProgramInsightsView'));
const TotalTripInsightsView = React.lazy(() => import('components/Views/TotalTripInsightsView'));
const ProjectManagementView = React.lazy(() => import('components/Views/ProjectManagementView'));
const TripCostCalculatorView = React.lazy(() => import('components/Views/TripCostCalculatorView'));

const useStyles = makeStyles((theme) => ({
  wrapperRoot: {
    display: 'flex',
    height: '100%',
    color: theme.appColors.neutral70,
  },
  mainRoot: {
    marginTop: '90px',
    flex: 1,
  },
  containerRoot: {
    marginTop: '20px',
    height: '100%',
  },
  loginContainer: {
    backgroundColor: theme.appColors.white,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  logo: {
    position: 'absolute',
    left: '20%',
    top: '2%',
  },
  gridContainerRoot: {
    height: '100%',
  },
}));

const App = () => {
  const classes = useStyles();
  const location = useLocation();
  const [user, setUserContextState] = useState({ isLoading: true });
  const [logoURL, setLogoURL] = useState(null);
  const [isAdvitoUser, setIsAdvitoUser] = useState(true);
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [prevLocation, setPrevLocation] = useState(history.location.pathname);
  const { webSocketConnect } = useWebSocket();

  const [
    getAppParams,
    {
      data: { appParams } = {},
      loading: appParamsLoading,
    },
  ] = useLazyQuery(APP_PARAMS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    authClient.query({
      query: QUERY_GET_USER_INFO,
    }).then(({ data }) => {
      setUserContextState(data.getUserInfo);
      if (data.getUserInfo) {
        if (!appParams && !appParamsLoading) {
          getAppParams();
        }
      } else if (window.location.origin !== window.loginURL
        && window.location.origin.includes(window.domainName)) {
        window.location.href = `${window.loginURL}?url=${encodeURI(window.location.href)}`;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.email) {
      if (window.location.origin === window.loginURL) {
        window.location.href = window.dashboardURL;
      }

      const isClientAdvito = user.clientName.toLowerCase() === CLIENTS.ADVITO;
      webSocketConnect(user.accessToken);
      setIsAdvitoUser(isClientAdvito);
      !isClientAdvito && setLogoURL(user.applicationAccess.client.logoPath);

      if (!appParams && !appParamsLoading) {
        getAppParams();
      }

      if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        ReactGA.send({ hitType: 'pageview', page: ROUTES.ROOT });
      }

      ReactGA.gtag('set', 'user_properties', {
        user_type: user.clientName === ADVITO_CLIENT_NAME
          ? USER_TYPE.ADVITO_INTERNAL
          : USER_TYPE.CLIENT_USER,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (history.location.pathname !== ROUTES.ERROR_500) {
      setPrevLocation(history.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const onLogoutHandler = () => {
    authClient.mutate({
      mutation: LOGOUT,
    }).then(() => {
      if (!window.location.origin.includes(window.domainName)) {
        setUserContextState(null);
        setLogoURL(null);
      }

      if (window.loginURL !== '{{login-url}}' && window.location.origin.includes(window.domainName)) {
        window.location.href = window.loginURL;
      }
    });
  };

  // TODO: ? what ?
  const pageTitle = (window.a360URL === window.location.origin
    || A360_ROUTES.includes(history.location.pathname))
    ? APP_TAB_NAMES.A360
    : APP_TAB_NAMES.DASHBOARD;

  const is360URL = window.location.origin.includes(window.domainName)
    && window.dashboardURL !== window.location.origin
    && window.loginURL !== window.location.origin;

  const mainView = useMemo(() => {
    if (is360URL || window.location.origin.includes('localhost')) {
      const redirectRoute = findFirstAvailableRoute(user);

      // TODO: revise the redirect logic
      return <Redirect to={redirectRoute} />;
    }

    return (
      <Suspense fallback={<></>}>
        <MainView />
      </Suspense>
    );
  }, [user]);

  // TODO: probably should go somewhere else, not the App file
  // but right now I can't think of any other place
  // hard to predict where in the app it would be needed later
  // I'm talking about the whole currentClient functionality
  const [currentClient, setCurrentClient] = useState(null);
  const currentClientContextValue = useMemo(() => ({
    client: currentClient,
    setClient: setCurrentClient,
  }), [currentClient]);

  if ((user?.email || user?.isLoading) && window.loginURL === window.location.origin) {
    return (
      <div className={classes.wrapperRoot}>
        <BasicLayout>
          <Loader />
        </BasicLayout>
      </div>
    );
  }

  if (user?.isLoading && (is360URL || window.location.origin.includes('localhost'))) {
    return (
      <div className={classes.wrapperRoot}>
        <BasicLayout>
          <Loader />
        </BasicLayout>
      </div>
    );
  }

  return user?.isLoading || user?.email
    ? (
      <AppParamsContext.Provider value={appParams}>
        <AppUserContext.Provider value={user}>
          <BreadcrumbProvider>
            <CurrentClientContext.Provider value={currentClientContextValue}>
              <Helmet>
                <title>
                  {pageTitle}
                </title>
              </Helmet>
              <div className={classes.wrapperRoot}>
                <Header
                  navigationOpen={navigationOpen}
                  isMainPage={location.pathname === ROUTES.ROOT}
                  setUser={setUserContextState}
                  onLogoutHandler={onLogoutHandler}
                  logoURL={logoURL}
                  userEmail={user?.email}
                  hasBreadcrumbs
                />

                {location.pathname !== ROUTES.ROOT
                  && (
                    <Suspense fallback={<></>}>
                      <Navigation
                        open={navigationOpen}
                        setOpen={setNavigationOpen}
                        user={user}
                        getActiveMenuList={getActiveMenuList}
                      />
                    </Suspense>
                  )}
                <main className={classes.mainRoot}>
                  <Container maxWidth="xl" classes={{ root: classes.containerRoot }}>
                    <Grid container classes={{ root: classes.gridContainerRoot }} spacing={5}>
                      <Switch>
                        <Route exact path={ROUTES.ROOT}>
                          {mainView}
                        </Route>

                        {
                          window.dashboardURL !== window.location.origin
                          && window.loginURL !== window.location.origin && (
                            <>
                              <Route path={ROUTES.PROJECT_MANAGEMENT.ROOT}>
                                <Suspense fallback={<></>}>
                                  <ProjectManagementView />
                                </Suspense>
                              </Route>
                              <Route path={ROUTES.TRAVEL_PROGRAM_INSIGHTS}>
                                <Suspense fallback={<></>}>
                                  <TravelProgramInsightsView
                                    setLogoPath={setLogoURL}
                                    isAdvitoUser={isAdvitoUser}
                                  />
                                </Suspense>
                              </Route>
                              <Route path={ROUTES.TOTAL_TRIP_INSIGHTS}>
                                <Suspense fallback={<></>}>
                                  <TotalTripInsightsView
                                    setLogoPath={setLogoURL}
                                    isAdvitoUser={isAdvitoUser}
                                  />
                                </Suspense>
                              </Route>
                              <Route path={ROUTES.SUSTAINABLE_COLLABORATION.ROOT}>
                                <Suspense fallback={<></>}>
                                  <SustainableCollaborationView
                                    setLogoPath={setLogoURL}
                                    isAdvitoUser={isAdvitoUser}
                                  />
                                </Suspense>
                              </Route>
                              <Route path={ROUTES.TRIP_COST_CALCULATOR}>
                                <Suspense fallback={<></>}>
                                  <TripCostCalculatorView />
                                </Suspense>
                              </Route>
                            </>
                          )
                        }

                        {/* TODO: next 2 error routes don't render at all */}
                        <Route exact path={ROUTES.ERROR_500}>
                          <Suspense fallback={<></>}>
                            <ErrorPage type="500" prevUrl={prevLocation} />
                          </Suspense>
                        </Route>

                        <Route>
                          <Suspense fallback={<></>}>
                            <ErrorPage type="404" />
                          </Suspense>
                        </Route>
                      </Switch>
                      {/* TODO: Temporary workaround to address users accessing content through cached or outdated links */}
                      {window.location.pathname === '/360-performance' && <Redirect from="/360-performance" to={ROUTES.TOTAL_TRIP_INSIGHTS} />}
                      {window.location.pathname === '/360-program-detail' && <Redirect from="/360-program-detail" to={ROUTES.TRAVEL_PROGRAM_INSIGHTS} />}
                    </Grid>
                  </Container>
                </main>
              </div>
              <Suspense fallback={<></>}>
                <BannerInfoDeploy />
              </Suspense>
            </CurrentClientContext.Provider>
          </BreadcrumbProvider>
        </AppUserContext.Provider>
      </AppParamsContext.Provider>
    )
    : (
      <div className={classes.loginContainer}>
        <Helmet><title>Login</title></Helmet>
        <Suspense fallback={<></>}>
          <Login
            amplify={false}
            user={user}
            setUser={setUserContextState}
          />
        </Suspense>
        <Suspense fallback={<></>}>
          <BannerInfoDeploy />
        </Suspense>
      </div>
    );
};

export default App;
