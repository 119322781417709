import { gql } from '@apollo/client';

export const EMISSIONS_CALC_DATA = gql`
  query EmissionsCalculatorData {
    emissionsCategories {
      marketTier
    }
    percentsOfVegetarian {
      percentOfVegetarianFood
    }
    coefficientsFoodWaste {
      foodWaste
    }
    disposalsFoodWaste {
      foodWasteDisposal
    }
    materialsSingleUsage {
      singleUsageItemMaterial
    }
    giftsCoefficient {
      giftPerParticipant
    }
  }
`;

export const CALCULATE_CO2 = gql`
  query CalculateCO2(
    $cap: String!
    $countryId: String!
    $dontKnowMeetingRooms: Boolean!
    $eventDuration: Int!
    $foodProvided: Boolean!
    $meetingDurationPerDay: String
    $meetingRooms: String
    $meetingRoomSize: String
    $meetingRoomsUsed: Boolean!
    $notePad: String!
    $participants: Int!
    $pen: String!
    $percentFoodWasted: String!
    $percentOfVegetarianMeal: Int
    $plasticBottles: Boolean!
    $stateId: String
    $totalFlipChart: String
    $tShirt: String!
    $vegetarianProvided: Boolean!
  ) {
    calculateCO2(
      cap: $cap
      countryId: $countryId
      dontKnowMeetingRooms: $dontKnowMeetingRooms
      eventDuration: $eventDuration
      foodProvided: $foodProvided
      meetingDurationPerDay: $meetingDurationPerDay
      meetingRooms: $meetingRooms
      meetingRoomSize: $meetingRoomSize
      meetingRoomsUsed: $meetingRoomsUsed
      notePad: $notePad
      participants: $participants
      pen: $pen
      percentFoodWasted: $percentFoodWasted
      percentOfVegetarianMeal: $percentOfVegetarianMeal
      plasticBottles: $plasticBottles
      stateId: $stateId
      totalFlipChart: $totalFlipChart
      tShirt: $tShirt
      vegetarianProvided: $vegetarianProvided
    ) {
      giftsEmissions
      foodAndBeverageEmission
      foodWasteEmission
      foodWasteKg
      foodWeightByEvent
      literOfWater
      meetingRoomEmissions
      plasticEmissions
      plasticWasteKg
      totalEmissions
    }
  }
`;
