import {
  FEATURES,
  SUSTAINABLE_COLLAB_FEATURES,
  TPI_FEATURES,
} from 'constant/application';
import { ROUTES } from 'constant/routes';

import { userHasFeatures } from './user';

export const findFirstAvailableRoute = (user) => {
  const route = ROUTES.ERROR_404;

  if (userHasFeatures(TPI_FEATURES, user)) {
    return ROUTES.TRAVEL_PROGRAM_INSIGHTS;
  }

  if (userHasFeatures(SUSTAINABLE_COLLAB_FEATURES, user)) {
    return ROUTES.SUSTAINABLE_COLLABORATION;
  }

  if (userHasFeatures(FEATURES.TC_CALCULATOR, user)) {
    return ROUTES.TRIP_COST_CALCULATOR;
  }

  return route;
};
