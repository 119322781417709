import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';

import { Paper } from '@advito/common';

const BasicLayout = ({ children }) => (
  <Grid item xs={12}>
    <Paper isMaxHeight>
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        { children }
      </Box>
    </Paper>
  </Grid>
);
BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
