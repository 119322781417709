import {
  from,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { ROUTES } from 'constant/routes';

import history from '../history';

const httpLink = new HttpLink({
  uri: window.apiBaseURL !== '{{api-base-url}}' ? window.apiBaseURL : 'http://localhost:4000/graphql',
  credentials: 'include',
});

const errorLink = onError(({
  graphQLErrors,
  networkError,
}) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
      case 'UNAUTHORIZED':
        if (window.loginURL !== '{{login-url}}' && window.location.origin.includes(window.domainName)) {
          window.location.href = window.loginURL;
        } else {
          window.location.href = ROUTES.ROOT;
        }
      }
    }
  }

  if (networkError
    && networkError.statusCode === 500
    && history.location.pathname !== ROUTES.ERROR_500) {
    history.push(ROUTES.ERROR_500);
  }
});

export const baseClient = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

const authHttpLink = new HttpLink({
  uri: window.apiAuthURL !== '{{api-auth-url}}' ? window.apiAuthURL : 'http://localhost:8080/dev/graphql',
  credentials: 'include',
});

const headersMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      application: window.application || 3,
    },
  });
  return forward(operation);
});

export const authClient = new ApolloClient({
  link: from([headersMiddleware, authHttpLink]),
  credentials: 'include',
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
